import React, { FC } from 'react'

import YamaguchiLogo from '../../../public/graphics/partners/yamaguchi.svg'
import CitilinkLogo from '../../../public/graphics/partners/citilink.svg'
import MuztorgLogo from '../../../public/graphics/partners/muztorg.svg'
import GaltsystemsLogo from '../../../public/graphics/partners/galtsystems.svg'
import StukaturnieLogo from '../../../public/graphics/partners/stukaturnie.svg'
import VseinstrumentiLogo from '../../../public/graphics/partners/vseinstrumenti.svg'
import Link from 'next/link'

const PARTNERS_COUNT = 900

const Partners: FC = () => {
  return (
    <>
      <div className='grid md:grid-cols-2 lg:grid-cols-3 gap-4 mb-4'>
        <div className='bg-grayscale-450 rounded-xl px-8 py-8'>
          <YamaguchiLogo
            className='mb-5 h-10'
            alt='логотип партнёра Yamaguchi'
          />
          <div className='font-display text-h400 font-medium mb-2'>
            Утром деньги — вечером стулья
          </div>
          <p className='font-body text-p250 text-grayscale-150'>
            Совместно разработали уникальное предложение для клиентов. Отправим
            КП через несколько минут.
          </p>
          <Link
            className='block font-display text-red-50 font-medium text-2xl mt-4'
            href='partners/yamaguchi'
          >
            Подробнее &#x2192;
          </Link>
        </div>
        <div className='bg-grayscale-450 rounded-xl px-8 py-8'>
          <MuztorgLogo className='mb-5 h-10' alt='логотип партнёра Музторг' />
          <div className='font-display text-h400 font-medium mb-2'>
            Лизинговая рапсодия
          </div>
          <p className='font-body text-p250 text-grayscale-150'>
            Колонки в кафе у дома или полное оснащение концертного зала –
            партнёрская форма передаст любую заявку.
          </p>
          <Link
            className='block font-display text-red-50 font-medium text-2xl mt-4'
            href='partners/muztorg'
          >
            Подробнее &#x2192;
          </Link>
        </div>
        <div className='bg-grayscale-450 rounded-xl px-8 py-8'>
          <GaltsystemsLogo
            className='mb-8 h-7'
            alt='логотип партнёра GALTSYSTEMS'
          />
          <div className='font-display text-h400 font-medium mb-2 fera-dot'>
            Прошлый опыт, новые выгоды
          </div>
          <p className='font-body text-p250 text-grayscale-150'>
            В 2014 году участвовали в зарождении рынка б/у серверов. 85%
            эффективности нового сервера за 50% его стоимости!
          </p>
          <Link
            className='block font-display text-red-50 font-medium text-2xl mt-4'
            href='partners/galtsystems'
          >
            Подробнее &#x2192;
          </Link>
        </div>
        <div className='bg-grayscale-450 rounded-xl px-8 py-8'>
          <VseinstrumentiLogo
            className='mb-5 h-10'
            alt='логотип партнёра Все инструменты'
          />
          <div className='font-display text-h400 font-medium mb-2'>
            Ваши проекты – наши решения
          </div>
          <p className='font-body text-p250 text-grayscale-150'>
            Инструменты, станки, строительное оборудование – возьмите в лизинг
            всё, что нужно для работы.
          </p>
          <Link
            className='block font-display text-red-50 font-medium text-2xl mt-4'
            href='partners/vseinstrumenti'
          >
            Подробнее &#x2192;
          </Link>
        </div>
        <div className='bg-grayscale-450 rounded-xl px-8 py-8'>
          <StukaturnieLogo
            className='mb-5 h-10'
            alt='логотип партнёра Штукатурные решения'
          />
          <div className='font-display text-h400 font-medium mb-2'>
            Лизинг без швов
          </div>
          <p className='font-body text-p250 text-grayscale-150'>
            Все для штукатурки, шпаклевки и покраски в одном месте!
          </p>
          <Link
            className='block font-display text-red-50 font-medium text-2xl mt-4'
            href='partners/shtukaturnie-resheniya'
          >
            Подробнее &#x2192;
          </Link>
        </div>
        <div className='bg-grayscale-450 rounded-xl px-8 py-8'>
          <CitilinkLogo className='mb-5 h-10' alt='логотип партнёра Ситилинк' />
          <div className='font-display text-h400 font-medium mb-2 fera-dot'>
            Айфон в лизинг? Что? Да
          </div>
          <p className='font-body text-p250 text-grayscale-150'>
            Любое оборудование от 100 000 рублей можно взять в лизинг. А
            специальные условия для клиентов Ситилинк помогут сэкономить.
          </p>
        </div>
      </div>
      <div className='flex items-center justify-center'>
        <p className='mb-4 font-display text-h400 font-medium text-center text-grayscale-200'>
          И ещё {PARTNERS_COUNT} партнёров
        </p>
      </div>
    </>
  )
}

export default Partners
